import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "posts-view" }
const _hoisted_2 = { class: "posts" }
const _hoisted_3 = {
  key: 0,
  class: "loading"
}
const _hoisted_4 = {
  key: 1,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostComponent = _resolveComponent("PostComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
        return (_openBlock(), _createBlock(_component_PostComponent, {
          key: post.id,
          post: post,
          previewImage: post.previewImage
        }, null, 8, ["post", "previewImage"]))
      }), 128))
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Loading..."))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Error: " + _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}