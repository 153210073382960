<template>
  <div class="home-container">
    <div class="intro">
      <h1 :key="currentText">{{ currentText }}</h1>
      <!-- key 속성 추가 -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";

export default defineComponent({
  name: "HomeView",
  setup() {
    const texts = [
      "안녕하세요",
      "제 블로그에 오신걸 환영합니다",
      "기술과 일상에 대한 이야기를 나눕니다",
    ];

    const currentText = ref(texts[0]);
    let index = 0;
    let intervalId: ReturnType<typeof setInterval> | null = null;

    const changeText = () => {
      index = (index + 1) % texts.length;
      currentText.value = texts[index];
    };

    onMounted(() => {
      intervalId = setInterval(changeText, 6000); // 5초마다 텍스트 변경
    });

    onBeforeUnmount(() => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    });

    return {
      currentText,
    };
  },
});
</script>

<style scoped>
/* 홈 컨테이너를 화면에 맞게 설정 */
.home-container {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  min-height: calc(100vh - 60px); /* 전체 높이에서 헤더 높이 제외 */
  text-align: center;
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 0; /* 패딩을 제거 */
  box-sizing: border-box;
}

/* intro 컨텐츠에 대한 스타일 */
.intro {
  max-width: 1000px; /* 최대 너비 설정 */
  padding: 20px; /* 여백 추가 */
}

h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
  color: var(--text-color);
  animation: fadeInOut 6s ease-in-out; /* 애니메이션 지속 시간을 5초로 설정 */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
