import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "create-post" }
const _hoisted_2 = { class: "editor-container" }
const _hoisted_3 = { class: "editor-left" }
const _hoisted_4 = { class: "button-group" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("h1", null, "새 게시물 작성", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
          class: "title-input",
          placeholder: "제목을 입력하세요..."
        }, null, 512), [
          [_vModelText, _ctx.title]
        ]),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.markdownContent) = $event)),
          class: "markdown-input",
          placeholder: "여기에 마크다운을 입력하세요...",
          onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleTab && _ctx.handleTab(...args))),
          onPaste: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args))),
          onDrop: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"])),
          onDragover: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
        }, null, 544), [
          [_vModelText, _ctx.markdownContent]
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.saveDraft && _ctx.saveDraft(...args))),
            class: "draft-button"
          }, "임시 저장"),
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.submitPost && _ctx.submitPost(...args))),
            class: "submit-button"
          }, "저장")
        ])
      ]),
      _createElementVNode("div", {
        class: "markdown-preview",
        innerHTML: _ctx.renderedMarkdown
      }, null, 8, _hoisted_5)
    ])
  ]))
}