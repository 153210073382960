<template>
  <div id="app">
    <Header
      :isDarkMode="isDarkMode"
      @toggle-dark-mode="toggleDarkMode"
      v-if="showHeader"
    />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import { computed, ref, watch } from "vue";
import Header from "./components/Header.vue";
import { useRoute } from "vue-router";

export default {
  name: "App",
  components: {
    Header,
  },
  setup() {
    const isDarkMode = ref(false);
    const route = useRoute();
    const showHeader = computed(() => route.name !== "CreatePost");

    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
    };

    watch(isDarkMode, (newValue) => {
      if (newValue) {
        document.documentElement.classList.add("dark-mode");
      } else {
        document.documentElement.classList.remove("dark-mode");
      }
    });

    return {
      isDarkMode,
      toggleDarkMode,
      showHeader,
    };
  },
};
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* #app에 전체 높이 설정 */
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
