import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = { class: "nav-menu" }
const _hoisted_4 = { class: "buttons" }
const _hoisted_5 = { class: "material-icons" }
const _hoisted_6 = {
  key: 0,
  class: "login-modal"
}
const _hoisted_7 = { class: "modal-content" }
const _hoisted_8 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/posts" }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Posts")
            ])),
            _: 1
          })
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("li", null, [
          _createElementVNode("a", { href: "https://github.com/seungheon123" }, "Github")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDarkMode && _ctx.toggleDarkMode(...args))),
          class: "dark-mode-button"
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.isDarkMode ? "light_mode" : "dark_mode"), 1)
        ]),
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: "/create-post",
              class: "write-button"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("글 작성")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
              class: "logout-button"
            }, " 로그아웃 "))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showLoginModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[10] || (_cache[10] = _createElementVNode("h2", null, "로그인", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.username) = $event)),
              placeholder: "Username"
            }, null, 512), [
              [_vModelText, _ctx.username]
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
              placeholder: "Password"
            }, null, 512), [
              [_vModelText, _ctx.password]
            ]),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
            }, "로그인"),
            _createElementVNode("button", {
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.closeLoginModal && _ctx.closeLoginModal(...args)))
            }, "취소"),
            (_ctx.loginError)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.loginError), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}