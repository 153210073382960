<template>
  <div class="post-card">
    <router-link :to="`/post/${post.id}`" class="post-link">
      <img
        v-if="previewImage"
        :src="previewImage"
        alt="Post Preview"
        class="post-preview-image"
      />
      <h3>{{ post.title }}</h3>
      <p>{{ post.content }}</p>
      <small>{{ formattedDate }}</small>
      <!-- 포맷된 날짜 사용 -->
    </router-link>
  </div>
</template>

<script lang="ts">
import { Post } from "@/models/Post";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PostComponent",
  props: {
    post: {
      type: Object as PropType<Post>,
      required: true,
    },
    previewImage: {
      type: String,
      default: null,
    },
  },
  computed: {
    formattedDate(): string {
      const date = new Date(this.post.createdAt);
      return date.toLocaleDateString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
});
</script>

<style scoped>
/* 게시물 카드 스타일 */
.post-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 상단과 하단 콘텐츠를 분리 */
  height: 320px; /* 전체 카드 높이를 고정 */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

/* 이미지 스타일 */
.post-preview-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* 카드 호버 효과 */
.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* 링크 스타일 */
.post-link {
  text-decoration: none;
  color: inherit;
}

/* 카드 내부 텍스트 스타일 */
.post-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.post-card p {
  flex-grow: 1; /* 남은 공간을 차지하여 높이를 맞춤 */
  color: #555;
  margin-bottom: 10px;
  overflow: hidden; /* 텍스트가 넘칠 경우 숨김 */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 최대 3줄까지 표시 */
  -webkit-box-orient: vertical;
}

.post-card small {
  color: #888;
  font-size: 0.9rem;
}
</style>
