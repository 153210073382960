import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "post-container"
}
const _hoisted_2 = { class: "post" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.post)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.post.title), 1),
          _createElementVNode("div", {
            innerHTML: _ctx.renderedMarkdown,
            class: "post-content"
          }, null, 8, _hoisted_3)
        ])
      ]))
    : _createCommentVNode("", true)
}