import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "post-card" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: `/post/${_ctx.post.id}`,
      class: "post-link"
    }, {
      default: _withCtx(() => [
        (_ctx.previewImage)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.previewImage,
              alt: "Post Preview",
              class: "post-preview-image"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("h3", null, _toDisplayString(_ctx.post.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.post.content), 1),
        _createElementVNode("small", null, _toDisplayString(_ctx.formattedDate), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}