import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.showHeader)
      ? (_openBlock(), _createBlock(_component_Header, {
          key: 0,
          isDarkMode: $setup.isDarkMode,
          onToggleDarkMode: $setup.toggleDarkMode
        }, null, 8, ["isDarkMode", "onToggleDarkMode"]))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ])
  ]))
}