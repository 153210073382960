<template>
  <div class="create-post">
    <h1>새 게시물 작성</h1>
    <div class="editor-container">
      <div class="editor-left">
        <input
          v-model="title"
          class="title-input"
          placeholder="제목을 입력하세요..."
        />
        <textarea
          v-model="markdownContent"
          class="markdown-input"
          placeholder="여기에 마크다운을 입력하세요..."
          @keydown="handleTab"
          @paste="handlePaste"
          @drop.prevent="handleDrop"
          @dragover.prevent
        ></textarea>
        <div class="button-group">
          <button @click="saveDraft" class="draft-button">임시 저장</button>
          <button @click="submitPost" class="submit-button">저장</button>
        </div>
      </div>

      <div class="markdown-preview" v-html="renderedMarkdown"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/github.css"; // 원하는 테마를 선택하세요.
import { markedHighlight } from "marked-highlight";
import axios from "axios";
import { SERVER_URL } from "@/main";
import { Hooks } from "marked";

export default defineComponent({
  name: "CreatePostView",
  setup() {
    const title = ref("");
    const markdownContent = ref("");

    marked.setOptions({
      pedantic: false,
      gfm: true,
      breaks: true,
    });

    marked.use(
      markedHighlight({
        langPrefix: "hljs language-",
        highlight(code, lang) {
          try {
            const language = hljs.getLanguage(lang) ? lang : "plaintext";
            return hljs.highlight(code, { language }).value;
          } catch (error) {
            console.error("Highlight.js error:", error);
            return code;
          }
        },
      })
    );

    const renderedMarkdown = computed(() => {
      return marked.parse(`# ${title.value}\n\n${markdownContent.value}`);
    });

    const submitPost = async () => {
      try {
        const response = await axios.post(
          `${SERVER_URL}/posts`,
          {
            title: title.value,
            content: markdownContent.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        window.location.href = `/post/${response.data.id}`;
      } catch (error) {
        console.error("Error saving post:", error);
        alert("글 저장에 실패했습니다.");
      }
    };
    const saveDraft = async () => {
      try {
        const response = await axios.post(
          `${SERVER_URL}/posts/draft`,
          {
            title: title.value,
            content: markdownContent.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        alert("글이 임시 저장되었습니다!");
      } catch (error) {
        console.error("Error saving draft:", error);
        alert("임시 저장에 실패했습니다.");
      }
    };
    const handleTab = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        event.preventDefault();
        const textarea = event.target as HTMLTextAreaElement;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;

        const before = textarea.value.substring(0, start);
        const after = textarea.value.substring(end);

        // 선택된 텍스트에 공백 2개 삽입 또는 커서 위치에 삽입
        textarea.value = before + "  " + after;

        // 커서를 삽입된 공백 뒤로 이동
        textarea.selectionStart = textarea.selectionEnd = start + 2;

        markdownContent.value = textarea.value;
      }
    };

    const uploadImage = async (file: File) => {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await axios.post(`${SERVER_URL}/images`, formData);
        return response.data.url; // 서버에서 반환된 이미지 URL
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("이미지 업로드에 실패했습니다.");
        return null;
      }
    };

    const handlePaste = async (event: ClipboardEvent) => {
      const items = event.clipboardData?.items;
      if (!items) return;

      for (const item of items) {
        if (item.type.startsWith("image/")) {
          const file = item.getAsFile();
          if (file) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
              markdownContent.value += `\n\n![이미지 설명](${imageUrl})\n\n`;
            }
          }
        }
      }
    };

    const handleDrop = async (event: DragEvent) => {
      const files = event.dataTransfer?.files;
      if (!files) return;

      for (const file of files) {
        if (file.type.startsWith("image/")) {
          const imageUrl = await uploadImage(file);
          if (imageUrl) {
            markdownContent.value += `\n\n![이미지 설명](${imageUrl})\n\n`;
          }
        }
      }
    };
    return {
      title,
      markdownContent,
      renderedMarkdown,
      saveDraft,
      submitPost,
      handleTab,
      handlePaste,
      handleDrop,
    };
  },
});
</script>

<style>
.create-post {
  padding: 20px;
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

h1 {
  text-align: center;
  margin: 0 0 20px 0;
  color: #333;
}

.editor-container {
  flex: 1;
  display: flex;
  gap: 20px;
}

.editor-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title-input {
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.markdown-input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.button-group {
  display: flex;
  gap: 8px; /* 버튼 사이의 간격 */
  justify-content: flex-end; /* 버튼을 오른쪽으로 정렬 */
  margin: 5px 5px; /* 버튼과 입력창 사이의 간격 */
}

.submit-button,
.draft-button {
  padding: 8px 12px;
  font-size: 0.9rem;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #fff;
  height: 40px;
  width: 100px; /* 버튼의 폭을 동일하게 설정 */
  min-width: 100px; /* 최소 폭 설정으로 버튼이 줄어들지 않게 함 */
}

.submit-button {
  background-color: #333;
}

.submit-button:hover {
  background-color: #000;
}

.draft-button {
  background-color: #777;
}

.draft-button:hover {
  background-color: #555;
}

.markdown-preview {
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--bg-color);
  overflow-y: auto;
}

.markdown-preview blockquote {
  border-left: 4px solid #444;
  display: block;
  margin: 2rem 0px;
  padding: 1rem 1rem 1rem 2rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.markdown-preview code {
  background-color: var(--bg-inline-code);
  padding: 0.2em 0.4em;
  font-size: 85%;
  border-radius: 3px;
}

.markdown-preview table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.markdown-preview th,
.markdown-preview td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.markdown-preview th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.markdown-preview tr:nth-child(even) {
  background-color: #f9f9f9;
}

.markdown-preview img {
  max-width: 100%; /* 부모 요소의 너비에 맞게 조정 */
  height: auto; /* 가로 비율에 맞게 높이 자동 조정 */
  display: block; /* 블록 요소로 표시 */
  margin: 10px auto; /* 상하 여백 10px, 좌우 자동 정렬로 가운데 배치 */
  max-height: 400px; /* 이미지의 최대 높이를 400px로 제한 (원하는 값으로 변경) */
}
</style>
