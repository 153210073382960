<template>
  <div class="posts-view">
    <section class="posts">
      <PostComponent
        v-for="post in posts"
        :key="post.id"
        :post="post"
        :previewImage="post.previewImage"
      />
    </section>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-if="error" class="error">Error: {{ error }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import PostComponent from "@/components/Post.vue";
import { Post } from "@/models/Post";
import axios from "axios";
import { SERVER_URL } from "@/main";

export default defineComponent({
  name: "PostsView",
  components: {
    PostComponent,
  },
  setup() {
    const posts = ref<Post[]>([]);
    const loading = ref<boolean>(true);
    const error = ref<string | null>(null);

    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/posts`);
        posts.value = response.data.map((post: Post) => {
          // 정규 표현식으로 첫 번째 이미지 URL 추출
          const imageMatch = post.content.match(/!\[.*?\]\((.*?)\)/);
          const previewImage = imageMatch ? imageMatch[1] : null;

          const plainText = post.content
            .replace(/^.*?!\[.*?\]\(.*?\).*$/gm, "") // 이미지 마크다운이 있는 줄 전체 제거
            .replace(/^.*?`{3}[\s\S]*?`{3}.*$/gm, "") // 코드 블록이 있는 줄 전체 제거
            .replace(/^.*?`.*?`.*$/gm, "") // 인라인 코드가 있는 줄 전체 제거
            .replace(/^#{1,6}\s.*$/gm, "") // 헤더가 있는 줄 전체 제거
            .replace(/^[-*+]\s.*$/gm, "") // 리스트가 있는 줄 전체 제거
            .replace(/^>\s.*$/gm, "") // 인용구가 있는 줄 전체 제거
            .replace(/^.*?\[.*?\]\(.*?\).*$/gm, "") // 링크가 있는 줄 전체 제거
            .replace(/^\s*\n/gm, "") // 빈 줄 제거
            .replace(/\n/g, " ") // 줄바꿈을 공백으로 변환
            .trim();

          return {
            ...post,
            content: plainText.slice(0, 50), // 텍스트만 추출한 내용 프리뷰 (100자 제한)
            previewImage, // 이미지 프리뷰
          };
        });
      } catch (err) {
        console.error("Error fetching posts:", err);
        error.value = "Failed to load posts.";
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchPosts();
    });

    return {
      posts,
      loading,
      error,
    };
  },
});
</script>

<style scoped>
.posts-view {
  padding: 20px;
  background-color: var(--bg-color);
  color: var(--text-color);
  min-height: 100vh;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--text-color);
}

.posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
</style>
